function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const keyPrefix = `logto`;
export class BrowserStorage {
    getKey(item) {
        if (item === undefined) {
            return `${keyPrefix}:${this.appId}`;
        }
        return `${keyPrefix}:${this.appId}:${item}`;
    }
    getItem(key) {
        var _this = this;
        return _async_to_generator(function*() {
            var _globalThis_localStorage;
            if (key === "signInSession") {
                var _globalThis_sessionStorage;
                return (_globalThis_sessionStorage = globalThis.sessionStorage) == null ? void 0 : _globalThis_sessionStorage.getItem(_this.getKey(key));
            }
            return (_globalThis_localStorage = globalThis.localStorage) == null ? void 0 : _globalThis_localStorage.getItem(_this.getKey(key));
        })();
    }
    setItem(key, value) {
        var _this = this;
        return _async_to_generator(function*() {
            if (key === "signInSession") {
                sessionStorage.setItem(_this.getKey(key), value);
                return;
            }
            localStorage.setItem(_this.getKey(key), value);
        })();
    }
    removeItem(key) {
        var _this = this;
        return _async_to_generator(function*() {
            if (key === "signInSession") {
                sessionStorage.removeItem(_this.getKey(key));
                return;
            }
            localStorage.removeItem(_this.getKey(key));
        })();
    }
    constructor(appId){
        this.appId = appId;
    }
}
