function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
export function gtag(...args) {
    var _window;
    var _dataLayer;
    // @ts-ignore
    if (window.gtag) window.gtag(...args);
    else // @ts-ignore
    ((_dataLayer = (_window = window).dataLayer) != null ? _dataLayer : _window.dataLayer = []).push(args);
}
gtag.event = function(name, opt) {
    try {
        gtag("event", name, _extends({}, opt));
    } catch (e) {}
};
//
gtag.set = function(key, value) {
    try {
        gtag("set", key, value);
    } catch (e) {}
}; //
